export default {
    zh:{
        roleCard:'角色管理',
        roleMenu:'角色菜单管理',
        role:{
            personal:'本人',
            department:'本部门',
            department_cascade:'本部门及下级部门',
            organization:'本机构',
            organization_cascade:'本机构及下级机构',
            menu_authorization_management:'菜单授权管理',
            portalDataLevelDesc:'是否具有首页工作台定制权限(如果为编辑，则具有权限)',
            selectAll:'全选',
            cancel:'取消',
            queryParam:{
                code:'编码',
                code_placeholder:'请输入编码模糊匹配查询...',
                name:'名称',
                name_placeholder:'请输入名称模糊匹配查询...',
            },
            columns:{
                OPERATE:'操作',
                EDIT:'编辑',
                ROLE_AUTHORIZATION:'角色授权',
                DELETE:'删除',
                VIEW:'查看',
                F_CODE:'编码',
                F_NAME:'名称',
                F_DATA_LEVEL:'数据权限',
                F_PORTAL_LEVEL:'门户权限',
            },
            form:{
                code:'编码',
                code_placeholder:'请输入编码',
                code_rule:'请输入编码',
                name:'名称',
                name_placeholder:'请输入名称',
                name_rule:'请输入名称',
                dataLevel:'数据权限',
                portalLevel:'门户权限',
                desc:'说明',
                desc_placeholder:'请输入说明',
            }
        }
    },
    en:{
        roleCard:'role management',
        roleMenu:'role menu management',
        role:{
            personal:'personal',
            department:'department',
            department_cascade:'department cascade',
            organization:'organization',
            organization_cascade:'organization cascade',
            menu_authorization_management:'Menu authorization management',
            portalDataLevelDesc:'Do you have permission to customize the home page workbench (if it is edit, then you have permission)',
            selectAll:'ALL',
            cancel:'CANCEL',
            queryParam:{
                code:'code',
                code_placeholder:'Please enter a code fuzzy matching query...',
                name:'name',
                name_placeholder:'Please enter a name fuzzy matching query...',
            },
            columns:{
                OPERATE:'operate',
                EDIT:'edit',
                ROLE_AUTHORIZATION:'role authorization',
                DELETE:'delete',
                VIEW:'view',
                F_CODE:'code',
                F_NAME:'name',
                F_DATA_LEVEL:'Data permissions',
                F_PORTAL_LEVEL:'Portal Permissions',
            },
            form:{
                code:'code',
                code_placeholder:'Please enter a code',
                code_rule:'Please enter a code',
                name:'name',
                name_placeholder:'Please enter a name',

                dataLevel:'Data permissions',
                portalLevel:'Portal Permissions',

                desc:'explain',
                desc_placeholder:'Please enter explain',
            }
        }
    }
}