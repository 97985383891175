import {ref, reactive, toRefs, onBeforeMount,onMounted, defineComponent, getCurrentInstance, computed} from 'vue';
import RoleMenuUtil,{IRoleMenuDataObj} from "@/views/sysviews/role/ts/roleMenuUtil";

const RoleMenuHelper = defineComponent({
    name: "roleMenu",
    title: "角色菜单管理",
    modelType:'card',
    fullscreen: true,
    setup(){
        let {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        let dataObj:IRoleMenuDataObj=reactive<IRoleMenuDataObj>({
            utilInst:{} as any,
            treeRef: {} as any,
            compParams: {
                modelPath:utils.Api.buildUrl('/roleMenu')
            },
            defaultProps: {
                disabled:()=>{return false},
                children: "children",
                label: "title"
            },
            topMenuTree: {} as any,
            activeMenuId: "",
            treeData: [] as any,
            disabled:false,
            menuSelPermissionItems: {} as any,
            menuSelKeys: [] as any,
            roleId: '',
            engineInst:{} as any,
            form: {} as any
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new RoleMenuUtil(proxy,dataObj);
        })

        const addOrLoad=async(engineInst:any)=>{
            await dataObj.utilInst.reloadRoleMenuInfo(engineInst);
        }

        const isActive=(menuId:string)=>{
            return menuId == dataObj.activeMenuId;
        }
        const tagClick=(menuId:string)=>{
            dataObj.utilInst.switchMenu(menuId);
        }

        const saveRoleMenuHandler=async()=>{
            const loading = proxy.$loading({lock: true, text: '正在保存,请稍后......', spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.7)'});
            let menuSelInfo=dataObj.utilInst.getSelMenuAndPermissionData();
            let menuSelKeys = menuSelInfo.menuSelKeys;
            let menuSelPermissionItems =menuSelInfo.menuSelPermissionItems;

            if (menuSelKeys.length == 0) {
                utils.UtilPub.info({ message: "没有选择任何菜单"});
                loading.close();
                return;
            }
            let menuPermissions=dataObj.utilInst.getSaveData(menuSelPermissionItems,menuSelKeys);//对上面saveData清洗之后的数据
            let params={roleId:dataObj.roleId,menuPermissions:menuPermissions};
            loading.close();
            let res = await utils.Api.saveRoleMenu(params);
            if(res.result){
                dataObj.engineInst.$parent.$parent.dialogVisible = false;
                utils.Tools.success({ message: res.msg });
            }
            loading.close();
        }

        const resetRoleMenuHandler=async ()=>{
            await dataObj.utilInst.reloadRoleMenuInfo(dataObj.engineInst);
            utils.Tools.success({ message: "表单重置成功!"});
        }

        const selAll=(node:any, data:any)=>{
            dataObj.utilInst.selAllPermission(data);
        }

        const cancelAll=(node:any, data:any)=>{
            dataObj.utilInst.cancelAllPermission(data);
        }

        const nodeCheckChange=(data:any,selfSelFlag:boolean,hasChildSelFlag:boolean)=>{
            if(!data.children){
                if(selfSelFlag){
                    const menuItemPermission=JSON.parse(data.menuItemPermission);
                    let permissionValue=[] as any;
                    menuItemPermission.forEach((item:any,index:number)=>{
                        permissionValue.push(item.code);
                    },permissionValue);
                    dataObj.menuSelPermissionItems[data.id]=permissionValue;
                }else{
                    dataObj.menuSelPermissionItems[data.id]=[];
                }
            }
        }
        const nodeClickHandler=(data:any,data2:any,data3:any)=>{
            // console.log(data);
            // console.log(data2);
            // console.log(data3);
        }
        const curCheckChange=(data:any,data1:any)=>{
            // console.log(data);
            // console.log(data1);
        }
        return{
            ...toRefs(dataObj),addOrLoad,isActive,tagClick,saveRoleMenuHandler,resetRoleMenuHandler,
            selAll,cancelAll,nodeCheckChange,nodeClickHandler,curCheckChange
        }
    },
    components: {}
});
export default RoleMenuHelper;